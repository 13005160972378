<template>
  <div style="display: contents">
    <v-btn
      color="color3"
      x-small
      text
      @click.stop="openMe"
    >
      Manage Requirements
    </v-btn>
    <v-navigation-drawer
      right
      v-model="drawer"
      fixed temporary
      :width="$vuetify.breakpoint.xs ? 350 : 500"
      :permanent="dirty || loading"
    >
      <!-- HEADER -->
      <template v-slot:prepend>
        <v-toolbar color="color2 color2Text--text" tile dense class="top">
          <v-toolbar-title>Agreement Requirements</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn
            color="color1 color1Text--text"
            fab
            small
            @click.stop="cancel"
            :disabled="loading"
          >
            <v-icon small>fas fa-times</v-icon>
          </v-btn>
        </v-toolbar>
      </template>
      <!-- FOOTER -->
      <template v-slot:append>
        <v-divider></v-divider>
        <v-card-actions>
          <v-row dense>
          <v-col cols="6">
            <v-btn
              :color="activeBtn.color"
              :disabled="activeBtn.disabled"
              :loading="activeBtn.loading"
              @click.stop="activeBtn.onClick"
              block
              class="py-9"
            >
              {{ activeBtn.text }}
            </v-btn>
          </v-col>
          <v-col cols="6">
            <v-btn
              color="error white--text"
              @click.stop="activeBtn.cancel"
              :disabled="activeBtn.loading"
              block
              class="py-9"
            >
            {{activeBtn.cancelText || 'Close'}}
            </v-btn>
          </v-col>
        </v-row>
        </v-card-actions>
      </template>
      <!-- BODY -->
      <v-container class="pt-12">
        <v-row dense>
          <v-col cols="12" v-for="a in displayAgreements.filter(f => !f.deleted)" :key="a.id">
            <agreement-requirement-card
              :agreement="a"
              :tournament="tournament"
              :disabled="!editing || loading"
              @delete-click="a.deleted = true"
            ></agreement-requirement-card>
          </v-col>
          <v-col cols="12" class="text-center">
            <v-expand-transition>
              <v-window v-model="window">
                <v-window-item :key="0">
                  <v-btn
                    color="color3"
                    text
                    v-if="editing && availableAgreements.length > 0"
                    @click.stop="window++"
                    :disabled="loading"
                  >add an agreement</v-btn>
                </v-window-item>
                <v-window-item :key="1">
                  <v-card>
                    <v-card-text>
                      <div>Which Agreement do you want to add?</div>
                      <v-select
                        :items="availableAgreements"
                        v-model="selectedAgreement"
                        label="Available Agreements"
                        return-object
                        item-text="title"
                        color="color3"
                        item-color="color3"
                      ></v-select>
                      <div>
                        <v-btn color="success white--text" @click.stop="addMe" :disabled="!selectedAgreement" class="mr-2">add</v-btn>
                        <v-btn color="error white--text" @click.stop="cancelAdd">cancel</v-btn>
                      </div>
                    </v-card-text>
                  </v-card>
                </v-window-item>
              </v-window>
            </v-expand-transition>
          </v-col>
          <v-col cols="12">
          </v-col>
        </v-row>
      </v-container>
    </v-navigation-drawer>
  </div>
</template>

<script>
import AgreementRequirementCard from './AgreementRequirementCard'

export default {
  props: ['tournament'],
  data () {
    return {
      drawer: false,
      options: [],
      loading: false,
      addAgreement: null,
      selectedAgreement: null,
      iAgreements: [],
      editing: false,
      window: 0
    }
  },
  computed: {
    displayAgreements () {
      return this.editing ? this.iAgreements : this.agreements
    },
    agreements () {
      return this.tournament.agreementStatuses
    },
    availableAgreements () {
      const xIds = this.iAgreements.map(m => m.agreementId)
      return this.options.filter(f => !xIds.includes(f.id))
    },
    activeBtn () {
      return !this.editing ? {
        color: 'color3Text color3--text',
        icon: 'fas fa-pencil-alt',
        onClick: this.edit,
        text: 'Edit',
        cancel: () => {
          this.cancel(true)
        },
        loading: this.loading
      } : {
        color: 'success white--text',
        icon: 'fas fa-times',
        onClick: this.onSaveClick,
        text: 'save',
        cancelText: 'Cancel',
        cancel: this.cancel,
        disabled: !this.dirty,
        loading: this.loading
      }
    },
    dto () {
      const r = []

      this.displayAgreements.forEach(a => {
        if (a.isATR && a.atrId === 0) {
          r.push(a)
        } else {
          const og = this.agreements.find(f => f.agreementId === a.agreementId)
          if (og) {
            if (og.deleted !== a.deleted || og.divisionIds !== a.divisionIds) {
              r.push(a)
            }
          }
        }
      })

      return r
    },
    dirty () {
      return this.dto.length > 0
    }
  },
  methods: {
    openMe () {
      this.getOptions()
      this.drawer = true
    },
    getOptions () {
      this.loading = true
      this.$VBL.agreements.organization.getAll(this.tournament.organization.username)
        .then(r => {
          this.options = r.data
        })
        .catch(e => console.log(e.response))
        .finally(() => { this.loading = false })
    },
    onSaveClick () {
      this.loading = true
      this.$VBL.agreements.tournament.postRequirements(this.dto, this.tournament.id)
        .then(r => {
          this.tournament.agreementStatuses = r.data
          this.cancel(true)
        })
        .catch(e => console.log(e.response))
        .finally(() => { this.loading = false })
    },
    edit () {
      this.iAgreements = JSON.parse(JSON.stringify(this.agreements.map(a => {
        return {
          agreement: a.agreement,
          agreementId: a.agreementId,
          atrId: a.atrId,
          divisionIds: a.divisionIds,
          deleted: a.deleted,
          isATR: a.isATR
        }
      })))
      this.editing = true
    },
    cancel (close) {
      console.log(close)
      this.selectedAgreement = null
      this.selectedMembership = null
      this.window = 0
      this.editing = false
      this.iAgreements = []
      this.loading = false
      if (close === true) {
        this.drawer = false
      }
    },
    addMe () {
      this.iAgreements.push({
        agreement: this.selectedAgreement.title,
        agreementId: this.selectedAgreement.id,
        atrId: 0,
        divisionIds: '*',
        deleted: false,
        isATR: true
      })
      this.window = 0
    },
    cancelAdd () {
      this.selectedAgreement = null
      this.window = 0
    },
    onDeleteClick (a) {
      console.log(a)
    }
  },
  components: {
    AgreementRequirementCard
  }
}
</script>
