<template>
  <v-card>
    <v-card-text>
      <div class="text-h6">{{ agreement.agreement }}</div>
      <v-select
        :items="options"
        v-model="divisions"
        label="Required For"
        chips
        multiple
        color="color3"
        item-color="color3"
        :disabled="disabled"
      ></v-select>
      <div class="caption grey--text d-flex align-center">
        Agreement Id: {{agreement.agreementId}}
        <v-spacer></v-spacer>
        <v-btn color="error" text icon small @click.stop="$emit('delete-click')" v-if="agreement.isATR && !disabled">
          <v-icon small>fas fa-trash</v-icon>
        </v-btn>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>

export default {
  props: ['agreement', 'tournament', 'disabled'],
  computed: {
    options () {
      return [
        { text: 'All Participants', value: '*' },
        ...this.tournament.publicDivisions.map(d => {
          return { text: d.name, value: `${d.id}` }
        })
      ]
    },
    divisions: {
      get () {
        return this.agreement.divisionIds.split(',')
      },
      set (v) {
        const last = v[v.length - 1]
        if (last === '*') {
          v = ['*']
        } else {
          v = v.filter(f => f !== '*')
        }
        this.agreement.divisionIds = v.join(',')
      }
    }
  }
}
</script>
